import axios from 'axios';
export default {
  data: () => ({
    //
    printDisabled: true,
    daysNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
    monthNames: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
    showPassword: false,
    menuItemListDefault: [{
      action: "mdi-menu",
      items: [
        { title: 'Op 1', icon: 'mdi-folder', link	:	'Empty1' },
        { title: 'Op 2', icon: 'mdi-folder', link	:	'Empty2' },      ], 
      title: 'Menu', 
    }],
    maskTel: '##-####-####',
  }),
  methods: {
    //
    setToken (val){
      this.$store.dispatch('loadToken', val);
      if(val === ''){
        sessionStorage.removeItem('localToken');
        this.$store.dispatch('loadLoggedIn', 0);
        this.$store.dispatch('loadUserProfile', {} );
      } else {
        this.$store.dispatch('loadLoggedIn', 1);
        sessionStorage.setItem('localToken', JSON.stringify(val));
      }
    },
    setFocus: async function (val) {
      await new Promise(resolve => setTimeout(resolve, 500)) // Pausa
      await this.$refs[val].focus();
    },
    editFocus: function (val){
      // this.isEditing = true
      if (typeof this.isEditing !== 'undefined'){
        this.isEditing = true
      }
      setTimeout(() => {
        this.$refs[val].focus();
      }, 500)
    },
    toggleShowOLD() {
      this.showPassword = !this.showPassword;
    },
    trimStr: function (val){
      if (val != '' && val != null) {
        return val.replace(/^\s+|\s+$/gm,'');
      }
      return '';
    },
    toggleTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      sessionStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
    },
    displayMsg (msgtxt, msgtype){
      this.$store.dispatch('loadMessage', {msg: msgtxt, type: msgtype});
    },
    async stringContains (str,char) {
      return str.toLowerCase().includes(char.toLowerCase())
    },
    async callAPI (data,route,token) { // REV data,route,token
      await this.toggleLoading();
      let dataResponse = await '';
      // REV let dataStatus = await null;
      // const token = await JSON.parse(sessionStorage.getItem('localToken'));
      
      await axios({
        method: 'post', url: this.urlApi + '/' + route,
        responseType: 'json',
        data: data,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }}).then(response => dataResponse = response.data).catch(e => {
          dataResponse = e.response.data;
          // dataResponse = e.response.data;
        });
      
      // REV *** await this.toggleLoading();
      /*
      if (await dataResponse.status){
        //
        if (await dataResponse.status === 'Token is Expired' || await dataResponse.status === 'Token is Invalid')  {
          //
          await this.displayMsg('Aviso: La sesion expiro. vuelva a ingresar', 'error');
          await this.loadRoute('Home', {});
        }
      } else {
        if (await this.urlApi.includes('localhost')) {
          //
        }
        return await dataResponse;
      } */
      await this.toggleLoading();
      
      if (await dataResponse.status === 'Token is Expired' || await dataResponse.status === 'Token is Invalid')  {
        await this.displayMsg('Aviso: La sesion expiro. vuelva a ingresar', 'error');
        await this.loadRoute('Home', {});
      }
      
      if (await dataResponse.message){
        if (await this.stringContains(dataResponse.message,'(') ) {
          await this.displayMsg(dataResponse.message.split('(')[0], 'error');
        } else {
          await this.displayMsg(dataResponse.message, 'error');
          return await {message: dataResponse.message};
        }
      } else {
        return await dataResponse;
      }
      // REV *** return await dataResponse;
    },
    async callAPIOLD (data,route,token,returnData) { // REV data,route,token,returnData
      await this.toggleLoading();
      let dataResponse = await '';
      // REV let dataStatus = await null;
      // const token = await JSON.parse(sessionStorage.getItem('localToken'));
      
      await axios({
        method: 'post', url: this.urlApi + '/' + route,
        responseType: 'json',
        data: data,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }}).then(response => dataResponse = response.data).catch(e => {
          dataResponse = e.response.data;
          // dataResponse = e.response.data;
        });
      
      await this.toggleLoading();
      if (await dataResponse.status){
        //
        if (await dataResponse.status === 'Token is Expired' || await dataResponse.status === 'Token is Invalid')  {
          //
          await this.displayMsg('Aviso: La sesion expiro. vuelva a ingresar', 'error');
          await this.loadRoute('Home', {});
        }
      } else {
      /* ***
      if (await dataResponse.status){
        dataStatus = await dataResponse.status;
      }
      if (await dataStatus === 'Token is Expired' || await dataStatus === 'Token is Invalid')  {
        await this.$store.dispatch('loadMessage', {
          msg: 'Aviso: La sesion expiro. vuelva a ingresar', type: 'error'
        });
        // *** await this.createLog(8, 'Expired');
        await this.$store.dispatch('loadUserData', this.userDataDefault);
        await this.$store.dispatch('loadMenuItemList', this.menuItemListDefault);
        await this.$store.dispatch('loadMyRoutes', []);
        // *** this.responseData = await [];
        await this.loadRoute('Home', {});
      }
      *** */
        if (await this.urlApi.includes('localhost')) {
          //
        }
        if (await returnData) {
          return await dataResponse;
        }
      }
    },
    async toggleLoading () {
      await this.$store.dispatch('loadOverlay');
    },
    async closeApp (idUser, param) {
      await this.createLog(idUser, param);
      await this.setToken('');
      await this.loadRoute('Home', {});
      
    },
    firstChart: function (val) {
      if (val.length !== 0) {
        return val.split(' ').map(word => word.charAt(0)).join('');
      } else {
        return '';
      }
    },
    async getProfile () {
      //
      if (await this.loggedIn === 1 && await this.token !== '') {
        //
        const token = await JSON.parse(sessionStorage.getItem('localToken'));
        if (await token) {
          //
          const apiRoute = await 'user';
          const formData = await {};
          let dataResponse = await this.callAPI(formData,apiRoute,token);

          await this.$store.dispatch('loadUserProfile', dataResponse );
          if (await this.routeName === 'Login' && await this.loggedIn === 1) {
            await this.loadRoute('Logged', {});
          }
        }
      }
    },
    async getProfileold2 () {
      //
      if (await this.loggedIn === 1 && await this.token !== '') {
        //
        const token = await JSON.parse(sessionStorage.getItem('localToken'));
        if (await token) {
          const apiRoute = await 'user';
          const formData = await {};
          let dataResponse = await this.callAPI(formData,apiRoute,token);
          // await alert(JSON.stringify(dataResponse));
          if (await dataResponse.status === 'Token is Expired' || await dataResponse.status === 'Token is Invalid')  {
            await this.displayMsg('Aviso: La sesion expiro. vuelva a ingresar', 'error');
            await this.loadRoute('Home', {});
            // await this.$router.push({name: 'Home', params: {}});
          }
          if (await this.routeName !== 'Logged' ) {
            //
            await this.loadRoute('Logged', {});
            // await this.$router.push({name: 'Logged', params: {}});
          }
          await this.$store.dispatch('loadUserProfile', dataResponse );
          //
        }
      }
    },
    async getProfileold () {
      const token = await JSON.parse(sessionStorage.getItem('localToken'));
      if (await token) {
        const apiRoute = await 'user';
        const formData = await {};
        const returnData = await true;
        let dataResponse = await this.callAPI(formData,apiRoute,token,returnData);
        if (sessionStorage.getItem('localToken')) {
          if(await dataResponse.active === '0'){
            await this.displayMsg('Aviso: Acceso no permitido, usuario deshabilitado', 'error');
            await this.closeApp();
          } else {
            dataResponse.firstChartName = await this.firstChart(dataResponse.name);
            await this.$store.dispatch('loadUserProfile', dataResponse );
            await this.getMenu();
            // REV *** await this.getMenuItemList();
            /* ***
                let menuOrg = await this.copyObjects(this.menuItemList);
                for (var z = 0; z < await menuOrg.length; z++){
                  // await this.panel.push(z);
                  // menuOrg[z].items = await undefined;
                  await menuOrg[z].items.sort((a, b) => {
                    return a.orderlist - b.orderlist;
                  });
                  await console.log(menuOrg[z].items);
                }
            *** */
          }
        }
      }
    },
    async getMenu () {
      await this.$store.dispatch('loadMenuItemList', this.menuItemListDefault);
      const token = await JSON.parse(sessionStorage.getItem('localToken'));
      if (await token) {
        await this.$store.dispatch('loadLoggedIn', 1);
        const apiRoute = await 'profile/' + this.userProfile.id; // 'menugetactive'
        const formData = await {};
        const returnData = await true;
        let dataResponse = await this.callAPI(formData,apiRoute,token,returnData);
        // REV await this.$store.dispatch('loadMenuItemList', dataResponse.data);
        if (await dataResponse.data.length !== 0){
          let menuOrg = await this.copyObjects(dataResponse.data);
          for (var z = 0; z < await menuOrg.length; z++){
            await menuOrg[z].items.sort((a, b) => {
              return a.orderlist - b.orderlist;
            });
          }
          await this.$store.dispatch('loadMenuItemList', menuOrg);
        }

        if (await this.routeName !== 'Logged' && await this.routeName === 'Login') {
          await this.loadRoute('Logged', {});
          // await this.$router.push({name: 'Logged', params: {}});
        }
      }
    },
    async getMenuItemListOLD () {
      const token = await JSON.parse(sessionStorage.getItem('localToken'));
      if (await token) {
        await this.$store.dispatch('loadLoggedIn', 1);
        let apiRoute = await 'profile/' + this.userData.id; // 'menugetactive'
        let formData = await {};
        const returnData = await true;
        let dataResponse = await this.callAPI(formData,apiRoute,token,returnData);
        let myRoutes = await [];
        let qItems = await [];
        await myRoutes.push('Home','Login','Logged','NotFound','Miscellan');
        await this.$store.dispatch('loadMyRoutes', myRoutes);
        if (await dataResponse.data.length !== 0){
          let menuOrg = await this.copyObjects(dataResponse.data);
          for (var z = 0; z < await menuOrg.length; z++){
            await menuOrg[z].items.sort((a, b) => {
              return a.orderlist - b.orderlist;
            });
          }
          await this.$store.dispatch('loadMenuItemList', menuOrg);
          await menuOrg.forEach(function(entry){
            entry.items.forEach(function(childrenEntry){
              myRoutes.push(childrenEntry.link);
              qItems.push(childrenEntry);
            });
          });
          await this.$store.dispatch('loadMyRoutes', myRoutes);
          await this.$store.dispatch('loadQItems', qItems);
        }
        if (await this.routeName !== 'Logged' && await this.routeName === 'Login') {
          await this.loadRoute('Logged', {});
          // await this.$router.push({name: 'Logged', params: {}});
        }
      } else {
        //
      }
    },
    leadZero: function (val, size) {
      let s = val+''
      while (s.length < size) s = "0" + s;
      return s;
    },
    makeString (length) {
      let result = '';
      let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
         result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    },
    getToday: function () {
      let today = null
      const tz = new Date()
      today = tz.getFullYear() + '-' +
        this.leadZero((tz.getMonth() + 1),2) + '-' + 
        this.leadZero(tz.getDate(),2)
      return today
    },
    getFullDate (val) {
      const dt = new Date(val);
      var hours = dt.getHours()
      var minutes = dt.getMinutes()
      var ampm = hours >= 12 ? 'pm' : 'am'
      hours = hours % 12
      hours = hours ? hours : 12
      minutes = minutes < 10 ? '0'+minutes : minutes
      var strTime = hours + ':' + minutes + ' ' + ampm
      // return this.daysNames[dt.getDay()] + ' ' +  dt.getDate() + ' ' + this.monthNames[dt.getMonth()] + ' ' + dt.getFullYear() + ' ' + strTime;
      // toDay ? toDay : ''    text.substring(0, 3)
      return (this.screenSize === 'md' ? this.daysNames[dt.getDay()] : this.daysNames[dt.getDay()].substring(0, 3))  + ' ' +  dt.getDate() + ' ' 
           + (this.screenSize === 'md' ? this.monthNames[dt.getMonth()] : this.monthNames[dt.getMonth()].substring(0, 3)) + ' ' 
           + dt.getFullYear() + ' - ' + strTime;
    },
    async loadRoute (route, param) {
      // alert(JSON.stringify(this.$route));
      if (await route === ''){
        route = 'NotFound';
      }
      if(await this.$route.name !== route){
        await this.$router.push({ name: route, params: param });
      }
    },
    clickItemMenuOLD: function (val) { // val
      //
      const resolve = this.$router.resolve({ name: val.link});
      const matched = resolve.resolved.matched.length;
      if (matched > 0) {
        if (this.routeName !== val.link){
          this.loadRoute(val.link,{});
          this.createLog(this.userProfile.id, val.link);
          this.$store.dispatch('loadTitle', val.title);
          if ( this.urlApi.includes('localhost') ) { console.log(val.vuefile); }
        }
      } else {
        this.loadRoute('Logged',{});
      }
      if(this.dialMenu) { this.dialMenu = false; }
    },
    copyObjects: function (val) {
      return JSON.parse(JSON.stringify(val));
    },
    setRoutes: function (comp,require,nameID,path) {
      this.$router.addRoute({
        component: () => import( '../views/' + comp),
        meta: { requiresAuth: require },
        name: nameID,
        path: path
      })
    },
    createLog: async function (idUser, event){
      const token = await JSON.parse(sessionStorage.getItem('localToken'));
      let apiRoute = await 'logcreate';
      let formData = await {
        user_id : idUser,
        event : event
      };
      const returnData = await false;
      // *** let logCreate = await this.callAPI(formData,apiRoute,token,returnData);
      await this.callAPI(formData,apiRoute,token,returnData);
    },
    async scrollToTop() {
      await window.scrollTo(0,0);
      document.body.scrollTop = await  0;
      document.documentElement.scrollTop = await  0;
    },
    //
    clickMethod: function (fnc, val) {
      if ( this.urlApi.includes('localhost') ) {
        console.log(fnc + ' ' + val);
      }
      this.fncName = fnc;
      this.fncVal = val;
      this[fnc](val);
    },
    getSearchValue: function (fnc, val) {
      // console.log(fnc + ' ' + val);
      this.searchValue = val;
    },
    setPanelBtnsOLD: function (val) {
      this.btnDefault = this.copyObjects(val);
    },
    setbtnsPanel: function (val) {
      this.btnsPanel = val;
    },
    dateToText: function (val) {
      const bdate = new Date(val);
      const utc = new Date(bdate.getTime() + bdate.getTimezoneOffset() * 60000);
      var ageDifMs = Date.now() - utc.getTime();
      var ageDate = new Date(ageDifMs);
      const d = utc.getDate();
      const m = this.monthNames[utc.getMonth()];
      const y = utc.getFullYear();
      const text = ((d <= 9 ? '0' + d : d) + '-' + m + '-' + y) + ' {' + (Math.abs(ageDate.getUTCFullYear() - 1970)) + '}';
      return text;
    },
    //
    isEmpty: function (dat, message, textfield) { // dat, message, textfield
      //
      let error = 0;
      // alert((typeof dat));
      // alert((dat.replace(/^\s+|\s+$/gm,'').length));

      if(( typeof dat)==='undefined'){
        this.displayMsg('Error: Campo validado no existe', 'error');
        error = 1;
      }

      
      if ( error === 0) {
        if(( typeof dat)==='number'){
          if( dat===0){
            error = 1;
          // } else {
          //   error = 0;
          }
        }

        if(( typeof dat)==='object'){
          error = 1;
        }

        if(( typeof dat)==='string'){
          dat.replace(/^\s+|\s+$/gm,'')
          if(dat.length===0){
            error = 1;
          } else {
            error = 0;
          }
        } else {
          // error = 1;
        }
        //
        
        if(error===0){
          return true;
        } else {
          // REV *** await 
          this.displayMsg(message, 'error');
          // setInterval(() => this.displayMsg(message, 'error'), 100);
          if(textfield.length !== 0){
            const ElementById = document.getElementById(textfield);
            // alert(JSON.stringify(ElementById));
            if(ElementById !== null){
              document.getElementById(textfield).focus();
            } else {
              // rev this.$refs.text_movil.focus();
              this.$refs[textfield].focus();
            }
          }
          // await this.scrollToTop();
          // await this.displayMsg(message, 'error');
          // setInterval(() => this.displayMsg(message, 'error'), 300);
          return false;
        }
      }
      
      // if ( error === 1) { return false; } else { return true; }
    },
    maskTelephone: function (val) {
      let x = val.replace(/\D/g, '').match(/(\d{0,2})(\d{0,4})(\d{0,4})/);
      return !x[2] ? x[1] : '' + x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '');
    },
    //
    
  },
  created () {
    //
  },
  mounted () {
    //
  },
  updated () {
    //
  },
  watch: {
    //
  },
  computed: {
    //
    screenSize () {
      return this.$vuetify.breakpoint.name;
    },
    loggedIn () {
      return this.$store.getters.getLoggedIn
    },
    urlApi: function () {
      return this.$store.getters.getUrlApi
    },
    token () {
      return this.$store.getters.getToken
    },
    timeOverlay () {
      return this.$store.getters.getOverlay
    },
    message () {
      return this.$store.getters.getMessage
    },
    userProfile () {
      return this.$store.getters.getUserProfile
    },
    dialMenu () {
      return this.$store.getters.getDialMenu
    },
    menuItemList () {
      return this.$store.getters.getMenuItemList
    },
    title () {
      return this.$store.getters.getTitle
    },
    routeName: function () {
      return this.$route.name;
    },
    classform() {
      if (this.$vuetify.breakpoint.name === 'xs'){
          return 'caption pa-0 ma-0 text-left';
      } else {
          return 'subheading pa-0 ma-0 text-left';
      }
    },
  }
}
