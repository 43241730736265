<template>
  <v-card
    class="mx-auto"
  >
      <v-toolbar dense flat>
        <v-toolbar-title :class="classform">{{$route.meta.title}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn outlined color="primary" class="ma-0 pa-1"
            @click.stop="btnNew();">
            <v-icon class="ma-0 pa-1">mdi-file-plus</v-icon>
            <span class="ma-0 pa-0" v-show="screenSize != 'xs'">Nuevo</span>
        </v-btn>
      </v-toolbar>
    <v-list dense>
      <template v-for="(item) in items">
        <v-list-item
          
          :key="item.title"
          @click.stop="clickList(item);"
        >
          <v-list-item-avatar>
            <!-- v-img :src="item.avatar"></v-img -->
            <v-icon>mdi-account</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{item.name}}</v-list-item-title>
            <v-list-item-subtitle>{{item.email}}</v-list-item-subtitle>
            <v-list-item-subtitle>{{item.personalemail}}</v-list-item-subtitle>
            <v-list-item-subtitle>{{item.movil}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-card>
</template>
<script>
  export default {
    data: () => ({
      items: {},
    }),
    methods: {
      //
      initialize: async function () {
        await this.getList();
      },
      getList: async function () {
        const token = await JSON.parse(sessionStorage.getItem('localToken'));
        if (await token) {
          let apiRoute = await 'userall';
          let formData = await {};
          let dataResponse = await this.callAPI(formData,apiRoute,token);
          this.items = await dataResponse.data
        }
      },
      async clickList (val) {
        await this.loadRoute('UserDtl', val);
      },
      async btnNew () {
        await this.displayMsg('Aviso: Ingresando nuevo registro', 'success');
        await this.loadRoute('UserDtl', {id: 0});
      }
    },
    created () {
      this.initialize();
    },
  }
</script>