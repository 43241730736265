<template>
  <v-app>
    <v-btn icon @click="toggle_dark_mode" v-show="loggedIn === 0">
          <v-icon>mdi-theme-light-dark</v-icon>
    </v-btn>
    <v-app-bar
      app
      color="primary"
      dark dense v-show="loggedIn === 1"
      >
      
      <!-- v-btn depresseds color="primary" class="ma-0 pa-1"
        @click.stop="$store.dispatch('loadDialMenu', '');"
        >
        <v-icon class="ma-0 pa-1" v-if="dialMenu === false">mdi-menu</v-icon>
        <v-icon left v-else>mdi-close</v-icon>
        <span class="ma-0 pa-0" v-show="screenSize != 'xs'">{{ dialMenu ? 'Cerrar' : 'Menu' }}</span>
      </v-btn -->
      <v-btn depresseds color="primary" class="ma-0 pa-1"
        v-show="routeName === 'Logged'"
        @click.stop="$store.dispatch('loadDialMenu', '');">
        <v-icon class="ma-0 pa-1">mdi-menu</v-icon>
        <span class="ma-0 pa-0" v-show="screenSize != 'xs'">Menu</span>
      </v-btn>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <v-toolbar-title :class="classform">{{ (toDay ? toDay : '' )}}</v-toolbar-title>
      
      <v-spacer></v-spacer>

      <v-btn depresseds color="primary" class="ma-0 pa-1"
        v-show="routeName !== 'Logged'"
        @click.stop="loadRoute('Logged', {});"
        >
        <v-icon>mdi-close</v-icon>
        <span class="ma-0 pa-0" v-show="screenSize != 'xs'">Cerrar</span>
      </v-btn>

      <v-btn depresseds color="primary" class="ma-0 pa-1"
        v-show="routeName === 'Logged'"
        @click.stop="loadRoute('Logout', {});">
        <v-icon class="ma-0 pa-1">mdi-exit-to-app</v-icon>
        <span class="ma-0 pa-0" v-show="screenSize != 'xs'">salir</span>
      </v-btn>

    </v-app-bar>

    <v-snackbar
      v-model="message.snackbar"
      
      centered
      :timeout="message.timeout"
      :color="message.color"
      >
      {{ message.text }}
    </v-snackbar>
    <v-overlay :value="timeOverlay">
      <v-progress-circular indeterminate size="100"></v-progress-circular>
    </v-overlay>

    <v-main>
      <router-view/>
    </v-main>

    <DIV v-show="urlApi.includes('localhost')">
      ROUTERNAME {{routeName}} /
      PARAMS {{$route.params}} <BR/>
      LOGGEDIN {{loggedIn}} / BREAKPOINT {{screenSize}} <BR/>
      USERPROFILE {{ userProfile }} <BR/>
      <v-text-field label="Token" v-model="token" disabled></v-text-field>
      {{ urlApi }} <BR/>

    </DIV>

  </v-app>
</template>


<script>
  export default {
    name: 'App',
    props: {
      //
    },
    components: {
      //
    },
    data: () => ({
      //
      // dialMenu: false,
      toDay: null,

    }),
    methods: {
      initialize: function () {
        //
      },
      watchrouteName: async function (val){
        await sessionStorage.setItem('localRouteName', String(val));
      },
      watchdialMenu: async function (val){
        if (await val) {
          //
          await this.loadRoute('LstOpt', {});
          /*
          if (await this.menuItemList[0].action !== 'mdi-menu') {
            this.items = await this.menuItemList;
          } else {
            this.items = await [];
            let msg = await 'Aviso. Este usuario no tiene opciones asignadas del sistema';
            let msgtype = await 'error';
            await this.displayMsg(msg, msgtype);
            await setTimeout(() => {
              this.dialMenu = false;
            }, 5000);
          }
          */
        } else {
          await this.loadRoute('Logged', {});
        }
      },
      toggle_dark_mode: function() {
        this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
        sessionStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
        if ( this.routeName === 'Login'){
        //   this.getFocus('usuario');
          document.getElementById('usuario').focus();
        }
      },
      getback: async function () {
        const token = await JSON.parse(sessionStorage.getItem('localToken'));
        if (await token) {
          await this.setToken(token);
          await this.getProfile();
        }
      },
      updateClock: function () {
        setInterval(() => this.setClock(), 1000);
      },
      setClock: function () {
        this.toDay = this.getFullDate(new Date());
      },
      processData: function () {
        //
      }
    },
    beforeCreate () {
      //
    },
    created () {
      this.updateClock();
    },
    mounted () {
      //
      const theme = sessionStorage.getItem("dark_theme");
      if (theme) {
        if (theme === "true") {
          this.$vuetify.theme.dark = true;
        } else {
          this.$vuetify.theme.dark = false;
        }
      } else if (
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
        ) {
          this.$vuetify.theme.dark = true;
          sessionStorage.setItem(
            "dark_theme",
            this.$vuetify.theme.dark.toString()
        );
      }
      if (this.routeName === 'LstOpt') {
        this.$store.dispatch('loadDialMenu', '');
      }
      if (sessionStorage.getItem('localToken')) {
        this.getback();
      }
    },
    updated () {
      //
    },
    watch: {
      routeName (val) {
        this.watchrouteName(val);
      },
      dialMenu (val) {
        this.watchdialMenu(val);
      }
    },
    computed: {
      //
    }
  }
</script>
