<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          :src="require('../assets/tulogo.png')"
          contain
          height="150"
        />
      </v-col>

      <v-col>
        <h1 class="display-2 font-weight-bold">
          Cerrar Sesion
        </h1>
        <p class="subheading font-weight-regular">
          Confirmar su salida del sistema
        </p>
      </v-col>
      
      <v-col cols="12">
        <!-- v-btn outlined color="primary" to="/Logged">
          Cancelar
        </v-btn -->
        <v-btn outlined color="primary" @click.stop="confirmExit()">
          Confirmar
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'LogoutForm',
    data: () => ({
      //
    }),
    methods: {
      //
      async confirmExit () {
        // await this.$store.dispatch('loadDialMenu', '');
        await this.displayMsg('Aviso: Saliendo del sistema, hasta luego', 'success');
        await this.loadRoute('Home',{});
      }
    }
  }
</script>