<template>
  <v-card
    max-width="375"
    class="mx-auto"
    >
    <v-toolbar dense flat>
      <v-toolbar-title :class="classform">{{$route.meta.title}}</v-toolbar-title>
    </v-toolbar>


    <v-list dense>
      <v-list-item>
        <v-list-item-icon>
          <v-icon color="indigo">
            mdi-account
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{userProfile.name}}</v-list-item-title>
          <v-list-item-subtitle>Nombre</v-list-item-subtitle>
        </v-list-item-content>

        <!-- v-list-item-icon>
          <v-icon>mdi-message-text</v-icon>
        </v-list-item-icon -->
      </v-list-item>

      <v-divider inset></v-divider>

      <v-list-item>
        <v-list-item-icon>
          <v-icon color="indigo">
            mdi-phone
          </v-icon>
        </v-list-item-icon>
        <!-- v-list-item-action></v-list-item-action -->

        <v-list-item-content>
          <v-list-item-title>{{userProfile.movil}}</v-list-item-title>
          <v-list-item-subtitle>Movil</v-list-item-subtitle>
        </v-list-item-content>

        <!-- v-list-item-icon>
          <v-icon>mdi-message-text</v-icon>
        </v-list-item-icon -->
      </v-list-item>

      <v-divider inset></v-divider>

      <v-list-item>
        <v-list-item-icon>
          <v-icon color="indigo">
            mdi-email
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{userProfile.personalemail}}</v-list-item-title>
          <v-list-item-subtitle>Personal</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <!-- v-list-item>
        <v-list-item-action></v-list-item-action>

        <v-list-item-content>
          <v-list-item-title>ali_connors@example.com</v-list-item-title>
          <v-list-item-subtitle>Work</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item -->

      <v-divider inset></v-divider>

      <v-list-item>
        <v-list-item-icon>
          <v-icon color="indigo">
            mdi-text
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{userProfile.comment}}</v-list-item-title>
          <v-list-item-subtitle>Notas</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider inset></v-divider>
    </v-list>
    <v-card-actions>
      <v-spacer></v-spacer>
      <!-- v-btn outlined color="primary" to="/Logout">
          Salir
      </v-btn -->
      <!-- v-btn outlined color="primary" class="ma-0 pa-1" v-show="routeName === 'Logged'"
        to="/Logout">
        <v-icon class="ma-0 pa-1">mdi-exit-to-app</v-icon>
        <span class="ma-0 pa-0" v-show="screenSize != 'xs'">SALIR</span>
      </v-btn -->
    </v-card-actions>
  </v-card>



</template>
<script>
  export default {
    name: 'App',
    props: {
      //
    },
    components: {
      //
    },
    data: () => ({
      //
    }),
    methods: {
      //
    }
  }
</script>