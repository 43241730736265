<template>
  <div class="home">
    <LoggedForm/>
  </div>
</template>

<script>
import LoggedForm from '@/components/LoggedForm.vue'

export default {
  name: 'Logged',
  components: {
    LoggedForm
  }
}
</script>
