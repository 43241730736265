<template>
  <v-card
    class="mx-auto"
    max-width="375"
    tile
  >
    <v-list dense>
      <v-subheader>{{$route.meta.title}}</v-subheader>
      <v-list-item-group
        v-model="selectedItem"
        color="primary"
      >
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          @click.stop="clickList(item.path);"
        >
          <v-list-item-icon>
            <v-icon color="indigo">{{item.icon}}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{item.text}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>
<script>
  export default {
    data: () => ({
      selectedItem: null,
      items: [
        { text: 'Horarios', icon: 'mdi-clock', path: '' },
        { text: 'Usuarios', icon: 'mdi-account', path: 'User' },
        { text: 'Permisos', icon: 'mdi-flag', path: '' },
      ],
    }),
    methods: {
      //
      async clickList (val) {
        await this.$store.dispatch('loadDialMenu', '');
        await this.loadRoute(val,{});
      }
    }
  }
</script>
