import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    //
    token: '',
    loggedIn: 0,
    overlay: false,
    title: '',
    userProfile: {},
    message: {
      snackbar: false,
      y: 'top',
      color: 'green',
      mode: '',
      timeout: 4000,
      text: '',
      autoheight: true
    },
    dialMenu: false,
    menuItemList: [{
      action: "mdi-menu",
      items: [
        { title: 'Op 1', icon: 'mdi-folder', link	:	'Empty1' },
        { title: 'Op 2', icon: 'mdi-folder', link	:	'Empty2' },      ], 
      title: 'Menu', 
    }],
    //
    protocol: location.protocol + '//', 
    hostName: location.hostname, 
    appName: 'logonvue',
    apiName: 'logonvueapi',
    apiRoute: '/api',
    portNumber: ':8000',
  },
  mutations: {
    //
    setToken (state, payload) {
      state.token = payload
    },
    setLoggedIn (state, payload) {
      state.loggedIn = payload
    },
    setMessage (state, payload) {
      state.message.snackbar = true
      state.message.text = payload.msg
      state.message.color = payload.type
    },
    setDialMenu (state,) {
      state.dialMenu = !state.dialMenu
    },
    setOverlay (state,) {
      state.overlay = !state.overlay
    },
    setUserProfile (state, payload) {
      state.userProfile = payload
    },
    setMenuItemList (state, payload) {
      state.menuItemList = payload
    },
    setTitle (state, payload) {
      state.title = payload;
      sessionStorage.setItem('localTitle', JSON.stringify(''));
      if (payload !== ''){
        sessionStorage.setItem('localTitle', JSON.stringify(payload));
      }
    },
  },
  actions: {
    //
    loadToken ({commit}, payload) {
      commit('setToken', payload)
    },
    loadLoggedIn ({commit}, payload) {
      commit('setLoggedIn', payload)
    },
    loadMessage ({commit}, payload) {
      commit('setMessage', payload)
    },
    loadDialMenu ({commit}, payload) {
      commit('setDialMenu', payload)
    },
    loadOverlay ({commit}, payload) {
      commit('setOverlay', payload)
    },
    loadUserProfile ({commit}, payload) {
      commit('setUserProfile', payload)
    },
    loadMenuItemList ({commit}, payload) {
      commit('setMenuItemList', payload)
    },
    loadTitle ({commit}, payload) {
      commit('setTitle', payload)
    },
  },
  modules: {
  },
  getters: {
    //
    getToken (state) {
      return state.token
    },
    getLoggedIn (state) {
      return state.loggedIn
    },
    getDialMenu (state) {
      return state.dialMenu
    },
    getOverlay (state) {
      return state.overlay
    },
    getMessage (state) {
      return state.message
    },
    getUserProfile (state) {
      return state.userProfile
    },
    getMenuItemList (state) {
      return state.menuItemList
    },
    getTitle (state) {
      return state.title
    },
    getUrlApi (state) {
      if (state.hostName === 'localhost'){
        return (state.protocol + state.hostName + 
                state.portNumber + state.apiRoute)
      } else {
        return (state.protocol + 
        state.hostName.replace(state.appName, state.apiName) + 
        state.apiRoute)
      }
    },
    //
  },
})
