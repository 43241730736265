<template>
  <div class="home">
    <UserForm/>
  </div>
</template>

<script>
import UserForm from '@/components/UserForm.vue'

export default {
  name: 'User',
  components: {
    UserForm
  }
}
</script>