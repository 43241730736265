<template>
  <div class="home">
    <LogoutForm/>
  </div>
</template>

<script>
import LogoutForm from '@/components/LogoutForm.vue'

export default {
  name: 'Logout',
  components: {
    LogoutForm
  }
}
</script>
