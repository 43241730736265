<template>
  <v-container>
    <v-card class="overflow-hidden ma-0 pa-0" flat>
      <v-toolbar flat dense>
        <v-toolbar-title :class="classform">{{$route.meta.title + '&nbsp;' + suffix}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          v-show="isEditing === true && editedItem.id !== 0"
          outlined color="primary" class="ma-0 pa-1"
          @click.stop="btnCancel()"
          >
          <v-icon color="blue darken-4"
            >mdi-close
          </v-icon>
          <span class="" v-show="screenSize != 'xs'"
            >Cancelar
          </span>
        </v-btn>
        <v-btn
          v-show="isEditing === false"
          outlined color="primary" class="ma-0 pa-1"
          @click.stop="btnEdit()">
          <v-icon
            >mdi-pencil
          </v-icon>
          <span class="" v-show="screenSize != 'xs'"
            >editar
          </span>
        </v-btn>
        <v-btn
          v-show="isEditing === true"
          outlined color="primary" class="ma-0 pa-1"
          @click.stop="btnSave()">
          <v-icon color="blue darken-4"
            >mdi-check
          </v-icon>
          <span class="" v-show="screenSize != 'xs'"
            >salvar
          </span>
        </v-btn>
        <v-btn outlined color="primary" class="ma-0 pa-1"
            v-show="isEditing === false || this.editedItem.id === 0"
            @click.stop="btnExit()">
            <v-icon class="ma-0 pa-1">mdi-chevron-left</v-icon>
            <span class="ma-0 pa-0" v-show="screenSize != 'xs'">atras</span>
        </v-btn>
      </v-toolbar>
      <v-card-text class="ma-0 pa-0">
        <v-container>
          <v-form>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field id="text_email" ref="text_email" v-on:keyup="keymonitor" :disabled="!isEditing"
                  hint="Usuario" persistent-hint
                  v-model="editedItem.email" label="Usuario"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field id="text_firstname" ref="text_firstname" v-on:keyup="keymonitor" :disabled="!isEditing"
                  :rules="[rules.required]"
                  v-model="editedItem.firstname" label="Nombre"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field id="text_midname" ref="text_midname" v-on:keyup="keymonitor" :disabled="!isEditing"
                  :rules="[rules.required]"
                  v-model="editedItem.midname" label="Paterno"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field id="text_lastname" ref="text_lastname" v-on:keyup="keymonitor" :disabled="!isEditing"
                  v-model="editedItem.lastname" label="Materno"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field id="text_password" ref="text_password" v-on:keyup="keymonitor" :disabled="!isEditing"
                  v-model="editedItem.password" label="Contraseña"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field id="text_movil" ref="text_movil" v-on:keyup="keymonitor" :disabled="!isEditing"
                  counter maxlength="12" v-mask="maskTel"
                  v-model="editedItem.movil" label="Movil"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field id="text_personalemail" ref="text_personalemail" v-on:keyup="keymonitor" :disabled="!isEditing"
                  :rules="[rules.email]"
                  v-model="editedItem.personalemail" label="Email"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field id="text_comment" ref="text_comment" v-on:keyup="keymonitor" :disabled="!isEditing"
                  v-model="editedItem.comment" label="Comentarios"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-select id="text_active" ref="text_active" 
                  v-model="editedItem.active"
                  label="Es Activo?"
                  v-on:keyup="keymonitor"
                  :items="selectItemYN"
                  :disabled="!isEditing"
                  item-text="item"
                  item-value="val"
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <!-- v-card-actions>
        <v-spacer></v-spacer>
      </v-card-actions -->
      {{editedItem}}
    </v-card>
  </v-container>
</template>

<script>
  export default {
    name: 'UserDtlForm',
    props: {
      //
    },
    components: {
      //
    },
    data: () => ({
      //
      isEditing: false,
      suffix: '',
      editedItem: {
        id: null,
      },
      defaultItem: {
        id: 0,
        name: '',
        email: '',
        firstname: '',
        midname: '',
        lastname: '',
        comment: '',
        personalemail: '',
        movil: '',
        password: '',
        active: '1',
        avatar: '',
      },
      selectItemYN: [
        { item: 'Si', val: '1' },
        { item: 'No', val: '0' }
      ],
      rules: {
        required: value => !!value || 'Campo Requerido.',
        counter: value => value.length <= 20 || 'Max 20 characters',
        email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              return pattern.test(value) || 'Invalid e-mail.'
        }
      },
      //
    }),
    methods: {
      initialize: async function () {
        if (await Object.keys(this.$route.params).length !== 0) {
          this.editedItem = await this.copyObjects(this.$route.params);
        }
        if (await Object.keys(this.$route.params).length === 0) {
          this.loadRoute('User', {});
        }
        if (await this.editedItem.id === 0) {
          this.isEditing = await true;
          this.editedItem = await this.copyObjects(this.defaultItem);
          await this.editFocus('text_email');
          this.suffix = await ' (Nuevo registro)';
        }
      },
      processData: function () {
        //
      },
      keymonitor: async function (event) {
        if (await event.key === 'Enter') {
          // *** await this.editFocus('text_email');
          await this.$refs.text_active.blur();
          await this.btnSave();
        }
      },
      btnEdit: function () {
        this.displayMsg('Aviso: Editando registro', 'success');
        this.isEditing = true;
        this.editFocus('text_email');
        this.suffix = ' (Editando registro)';
      },
      btnCancel: function () {
        this.editedItem = this.copyObjects(this.$route.params);
        this.displayMsg('Aviso: Se cancelo la edicion', 'error');
        this.isEditing = false;
        this.suffix = '';
      },
      btnSave: async function () {
        // this.isEditing = await false;
        
        if(await this.validationFields()){
          //
          const token = await JSON.parse(sessionStorage.getItem('localToken'));
          if (await token) {
            //
            this.suffix = await ' (Salvando información... espere)';
            let formData = await this.editedItem;
            let apiRoute = await 'usersave';
            formData['employee_id'] = await this.userProfile.id;
            formData.name = (await formData.firstname + ' ' + 
            await formData.midname + ' ' + 
            await formData.lastname).trim();
            if(await formData.password.length === 0 &&
              await formData.id === 0){
              formData.password = await this.makeString(6);
            }
            let dataResponse = await this.callAPI(formData,apiRoute,token);
            console.log(dataResponse);
            // await alert(JSON.stringify(dataResponse));
            //
          }
        }
      },
      validationFields: function () {
        let isValid = true;

        if(isValid===true){
          isValid = this.isEmpty(
            this.editedItem.email,
            'El Campo Usuario no puede ser blanco',
            'text_email'
          );
        }

        if(isValid===true){
          isValid = this.isEmpty(
            this.editedItem.firstname,
            'El Campo Nombre no puede ser blanco',
            'text_firstname'
          );
        }

        if(isValid===true){
          isValid = this.isEmpty(
            this.editedItem.midname,
            'El Campo Paterno no puede ser blanco',
            'text_midname'
          );
        }

        if(isValid===true){
          isValid = this.isEmpty(
            this.editedItem.password,
            'El Campo Contraseña no puede ser blanco',
            'text_password'
          );
        }

        if(isValid===true){
          isValid = this.isEmpty(
            this.editedItem.active,
            'El Campo Activo no puede ser blanco',
            'text_active'
          );
        }

        return isValid;
      },
      btnExit: async function () {
        this.isEditing = await false;
        await this.displayMsg('Aviso: Desplegando listado', 'success');
        await this.loadRoute('User', {});
      },
    },
    created () {
      this.initialize();
    },
    mounted () {
      //
    },
    updated () {
      //
    },
    watch: {
      //
    },
    computed: {
      //
    }
  }
</script>

