<template>
  <div class="home">
    <LstOptForm/>
  </div>
</template>

<script>
import LstOptForm from '@/components/LstOptForm.vue'

export default {
  name: 'LstOpt',
  components: {
    LstOptForm
  }
}
</script>