<template>
  <div class="home">
    <UserDtlForm/>
  </div>
</template>

<script>
import UserDtlForm from '@/components/UserDtlForm.vue'

export default {
  name: 'UserDtl',
  components: {
    UserDtlForm
  }
}
</script>