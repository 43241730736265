import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import '@babel/polyfill'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'

import mixins from './mixins/mixins'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueTheMask from 'vue-the-mask'

Vue.mixin(mixins)
Vue.config.productionTip = false
Vue.use(VueTheMask)

new Vue({
  router,
  store,
  vuetify,
  axios,
  VueAxios,
  render: h => h(App)
}).$mount('#app')
