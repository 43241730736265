<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          :src="require('../assets/tulogo.png')"
          contain
          height="150"
        />
      </v-col>
      <v-row>
        <v-col
          cols="12"
          sm="6">
          <v-text-field
            ref="usuario"
            id="usuario"
            name="usuario"
            v-model="username"
            label="Usuario"
            dense outlined clearable
            v-on:keyup="keymonitor">
          </v-text-field>
        </v-col>
        <v-col
          cols="12"
          sm="6">
          <v-text-field
            v-model="password"
            label="Contrasena"
            ref="password"
            id="password"
            name="password"
            :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPass ? 'text' : 'password'"
            prepend-inner-icon="mdi-lock"
            dense clearable outlined
            v-on:keyup="keymonitor"
            @click:append="showPass = !showPass">
          </v-text-field>
        </v-col>
      </v-row>
      <v-col cols="12">
        <v-btn outlined color="primary" class="ma-2" to="/">
          Atras
        </v-btn>
        <v-btn outlined color="primary" class="ma-2" @click.stop="checkLogin()">
          Entrar
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'LoginForm',
    props: {
      //
    },
    components: {
      //
    },
    data: () => ({
      username: '',
      password: '',
      showPass: false,
    }),
    methods: {
      //
      keymonitor: function (event) {
        if (event.key === 'Enter'){
          this.checkLogin();
        }
      },
      async checkLogin () {
        this.username = await this.trimStr(this.username);
        this.password = await this.trimStr(this.password);
        if (await this.username === '' && await this.password === '') {
          this.setFocus('usuario'); this.displayMsg('Aviso: Ingresar Usuario', 'error');
        }
        if (await this.username !== '' && await this.password === '') {
          this.setFocus('password'); this.displayMsg('Aviso: Ingresar Contraseña', 'error');
        }
        if (await this.username === '' && await this.password !== '') {
          this.setFocus('usuario'); this.displayMsg('Aviso: Ingresar Usuario', 'error');
        }
        if (await this.username !== '' && this.password !== '') {
          await this.setToken('');
          const formData = await {
            email: this.username, 
            password: this.password,
            evento: ''
          };
          const apiRoute = await 'login';
          const dataResponse = await this.callAPI(formData,apiRoute,this.token);
          
          if (await dataResponse.error){
            if(await dataResponse.error === 'invalid_credentials'){
              await this.displayMsg('Aviso: Usuario o contraseña no es valida !!', 'error');
            } else {
              await this.displayMsg('Aviso: acceso denegado ' + dataResponse.error, 'error');
            }
          }
          if (await dataResponse.token){
            await this.displayMsg('Aviso: Acceso permitido, espere...', 'success');
            await this.setToken(dataResponse.token);
            await this.getProfile();
            // REV *** await this.createLog(this.userProfile.id, 'logUser');
            // REV *** await this.getTokenInfoOLD();
          }
          //
        }
      },
      async checkLoginOLD () {
        this.username = await this.trimStr(this.username);
        this.password = await this.trimStr(this.password);
        if (await this.username === '' && await this.password === '') {
          this.setFocus('usuario'); this.displayMsg('Aviso: Ingresar Usuario', 'error');
        }
        if (await this.username !== '' && await this.password === '') {
          this.setFocus('password'); this.displayMsg('Aviso: Ingresar Contraseña', 'error');
        }
        if (await this.username === '' && await this.password !== '') {
          this.setFocus('usuario'); this.displayMsg('Aviso: Ingresar Usuario', 'error');
        }
        if (await this.username !== '' && this.password !== '') {
          await this.setToken('');
          const formData = await {
            email: this.username, 
            password: this.password,
            evento: ''
          };
          const apiRoute = await 'login';
          const returnData = await true;
          if (await !returnData) {
            await this.callAPI(formData,apiRoute,this.token,returnData);
          } else {
            const dataResponse = await this.callAPI(formData,apiRoute,this.token,returnData);
            if (await dataResponse.message){
              if (await this.stringContains(dataResponse.message,'(') ) {
                await this.displayMsg(dataResponse.message.split('(')[0], 'error');
              } else {
                await this.displayMsg(dataResponse.message, 'error');
              }
            }
            if (await dataResponse.error){
              if(await dataResponse.error === 'invalid_credentials'){
                await this.displayMsg('Aviso: Usuario o contraseña no es valida !!', 'error');
              } else {
                await this.displayMsg('Aviso: acceso denegado ' + dataResponse.error, 'error');
              }
            }
            if (await dataResponse.token){
              await this.displayMsg('Aviso: Acceso permitido, espere...', 'success');
              await this.setToken(dataResponse.token);
              await this.getProfile();
              // REV *** await this.createLog(this.userProfile.id, 'logUser');
              // REV *** await this.getTokenInfoOLD();
            }
            
          }
        }
      },
    },
    created () {
      //
      if (this.loggedIn === 1) {
        this.loadRoute('Logged', {});
        // this.$router.push({name: 'Logged', params: {}});
      }
      setTimeout(() => {
        if (this.loggedIn === 0 && this.$route.name === 'Login') { 
          this.setFocus('usuario');
        }
      }, 200)
    },
    mounted () {
      //
      if(this.urlApi.includes('localhost')){
        this.username = 'user';
        this.password = 'admin';
      }
    },
  }
</script>