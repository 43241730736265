import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Logged from '../views/Logged.vue'
import Logout from '../views/Logout.vue'
import LstOpt from '../views/LstOpt.vue'
import User from '../views/User.vue'
import UserDtl from '../views/UserDtl.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { requiresAuth: false, title: ''}
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login,
    meta: { requiresAuth: false, title: ''}
  },
  {
    path: '/Logged',
    name: 'Logged',
    component: Logged,
    meta: { requiresAuth: true, title: 'Principal'}
  },
  {
    path: '/LstOpt',
    name: 'LstOpt',
    component: LstOpt,
    meta: { requiresAuth: true, title: 'Opciones de sistema'}
  },
  {
    path: '/User',
    name: 'User',
    component: User,
    meta: { requiresAuth: true, title: 'Lista de Usuarios'}
  },
  {
    path: '/UserDtl',
    name: 'UserDtl',
    component: UserDtl,
    meta: { requiresAuth: true, title: 'Datos de usuario'}
  },
  {
    path: '/Logout',
    name: 'Logout',
    component: Logout,
    meta: { requiresAuth: true, title: ''}
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'PathMatch',
    component: () => import('../views/PathMatch.vue'),
    meta: { requiresAuth: false, title: ''}
  },
  {
    path: '/NotFound',
    name: 'NotFound',
    component: () => import('../views/NotFound.vue'),
    meta: { requiresAuth: false, title: ''}
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

/*
const router = new VueRouter({
  routes
})
*/

let router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
})

router.beforeEach((to, from, next) => {
  if (!to.matched.length) {
    // alert('NO router component');
    // next({ name: "NotFound" })
  }
  if (to.meta.requiresAuth) {
    if (sessionStorage.getItem('localToken')) {
      next()
    } else {
      next({ name: "Login" })
    }
  } else {
    next()
  }
  
})

export default router
