<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          :src="require('../assets/tulogo.png')"
          contain
          height="200"
        />
      </v-col>

      <v-col>
        <h1 class="display-2 font-weight-bold">
          Bienvenido a
        </h1>
        <p class="subheading font-weight-regular">
          Gracias por tu visita,
          <br>click en el boton de ingresar
        </p>
      </v-col>
      
      <v-col cols="12">
        <v-btn outlined color="primary" to="/Login">
          Ingresar
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'HelloWorld',

    data: () => ({
      //
    }),
    created () {
      this.setToken('');
    },
  }
</script>
